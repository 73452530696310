import { GetContractByIdQueryKey, Params, getContractById } from 'api/contract';
import { ContractResponse } from 'api/contract/types';
import { useQuery } from 'react-query';

export const useContractQuery = ({
    contractId,
    params,
    skipId = false,
}: {
    contractId?: number;
    params?: Params;
    skipId?: boolean;
}) => {
    const query = ['contract'] as unknown as GetContractByIdQueryKey;
    const enabled = {
        enabled: !!contractId || skipId,
    };
    if (contractId) {
        query.push(contractId);
    }
    if (params) {
        query.push(params);
    }

    return useQuery<ContractResponse, Error, ContractResponse, typeof query>(
        query,
        getContractById,
        enabled
    );
};
