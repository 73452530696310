import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'api/authentication/types';

export interface ClaimResponse {
    id: number;
    dateCreated: string;
    dateModified: string;
    contract: {
        id: number;
    } | null;
    type: string;
    claimStatus: {
        id: number;
        name: string;
        code: string;
        dateCreated: string;
        dateModified: string;
    } | null;
}

export interface PatchClaimResponse extends Omit<ClaimResponse, 'contract'> {
    contract: number | null;
}

interface Note {
    description?: string;
    summary?: string;
}

export interface PatchClaimBody {
    claimStatusId: number;
    reason?: string;
    note?: Note;
}

export interface DatabaseClaim {
    id: number;
    date_created: string;
    date_modified?: string | null;
    contract_id: number;
    type?: string | null;
    claim_start_date?: string | null;
    sk_vulnerable_customer?: string | null;
    sk_urgency?: string | null;
    claim_status_id?: number | null;
    last_modified_by?: string | null;
    charge_total?: number | null;
    charge_total_cost?: number | null;
    charge_vat?: number | null;
    charge_final?: number | null;
    claim_status_name?: string | null;
    labour_total?: number | null;
    engineer_parts_total?: number | null;
    hometree_parts_total?: number | null;
    claim_cost_limit_gbp?: number | null;
    alternative_accommodation?: number | null;
    claim_insurer_approval_required?: number | null;
    annual_claim_cost_limit_gbp?: number | null;
}

export const databaseClaimSchema: JSONSchemaType<DatabaseClaim> = {
    type: 'object',
    properties: {
        id: { type: 'integer' },
        date_created: { type: 'string', format: 'date-time' },
        date_modified: { type: 'string', format: 'date-time', nullable: true },
        contract_id: { type: 'integer' },
        type: { type: 'string', nullable: true },
        claim_start_date: { type: 'string', format: 'date-time', nullable: true },
        sk_vulnerable_customer: { type: 'string', nullable: true },
        sk_urgency: { type: 'string', nullable: true },
        claim_status_id: { type: 'integer', nullable: true },
        last_modified_by: { type: 'string', nullable: true },
        charge_total: { type: 'number', nullable: true },
        charge_total_cost: { type: 'number', nullable: true },
        charge_vat: { type: 'number', nullable: true },
        charge_final: { type: 'number', nullable: true },
        claim_status_name: { type: 'string', nullable: true },
        labour_total: { type: 'number', nullable: true },
        engineer_parts_total: { type: 'number', nullable: true },
        hometree_parts_total: { type: 'number', nullable: true },
        claim_cost_limit_gbp: { type: 'number', nullable: true },
        alternative_accommodation: { type: 'number', nullable: true },
        claim_insurer_approval_required: { type: 'number', nullable: true },
        annual_claim_cost_limit_gbp: { type: 'number', nullable: true },
    },
    required: ['id', 'date_created', 'contract_id'],
};

export type GetClaimResponse = PandoraErrorResponse | DatabaseClaim;

export const getClaimResponseSchema: JSONSchemaType<GetClaimResponse> = {
    type: 'object',
    anyOf: [databaseClaimSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};
