import { NewTabIcon } from 'assets/pandoraIcons';
import styled from 'styled-components';

const Container = styled.article`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h6 {
        margin: 0;
        line-height: 1.5;
    }
    p {
        margin: 0;
    }
`;
const IconContainer = styled.figure`
    margin: 0 0 0 0.5rem;
    width: 1.2rem;
    height: 1.2rem;
    svg {
        width: 1.2rem;
        height: 1.2rem;
        stroke: ${({ theme }) => theme.colors.gray_500};
        fill: transparent;
    }
`;

const Header = styled.header`
    display: flex;
    align-items: center;
`;

interface Props {
    header: string;
    body?: string | null;
    link?: string;
    openInNewTab?: boolean;
}

export const LinkCell = ({ header, body, link, openInNewTab }: Props) => {
    const target = openInNewTab && openInNewTab ? '_blank' : '';

    return (
        <Container>
            <Header>
                <h6>{header}</h6>
                {link ? (
                    <a href={link} target={target} aria-label="New">
                        <IconContainer>
                            <NewTabIcon />
                        </IconContainer>
                    </a>
                ) : null}
            </Header>
            <p>{body}</p>
        </Container>
    );
};
