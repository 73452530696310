import { XCircle } from 'assets/pandoraIcons';
import { DatabaseClaim } from 'features/claim/api/claim/type';
import { toCurrency } from 'utils/toCurrency';
import styles from './index.module.scss';

interface Props {
    claim: DatabaseClaim;
    setShowOtherCost: (show: boolean) => void;
}

export const CostBreakdown = ({ claim, setShowOtherCost }: Props) => {
    return (
        <div className={styles.costBreakDownContainer}>
            <div className={styles.costWrapper}>
                <span className={styles.costTitle}>TOTAL COST TO DATE</span>
                <button
                    type="button"
                    className={styles.closeButton}
                    onClick={() => setShowOtherCost(false)}
                    aria-label="show cost"
                >
                    <XCircle />
                </button>
            </div>
            <hr />
            <div className={styles.costWrapper}>
                <span className={styles.costSubtitle}>Total cost of claim</span>
                <span className={styles.costValue} data-testid="cost-total">
                    {toCurrency(claim.charge_total_cost || 0)}
                </span>
            </div>
            <hr />
            <div className={styles.costWrapper}>
                <span className={styles.costSubtitle}>Total cost of labour</span>
                <span className={styles.costValue} data-testid="labour-total">
                    {toCurrency(claim.labour_total || 0)}
                </span>
            </div>
            <hr />
            <div className={styles.costWrapper}>
                <span className={styles.costSubtitle}>
                    Total cost of parts sourced and paid for by engineer
                </span>
                <span className={styles.costValue} data-testid="engineer-part-total">
                    £{(claim.engineer_parts_total || 0).toFixed(2)}
                </span>
            </div>
            <hr />
            <div className={styles.costWrapper}>
                <span className={styles.costSubtitle}>Total cost of parts sourced by us</span>
                <span className={styles.costValue} data-testid="hometree-part-total">
                    £{(claim.hometree_parts_total || 0).toFixed(2)}
                </span>
            </div>
        </div>
    );
};
