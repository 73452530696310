import { ReactElement } from 'react';
import styles from './index.module.scss';

interface Props {
    header: string | ReactElement;
    body: string | ReactElement;
    bodyStyle?: string;
}

export const Cell = ({ header, body, bodyStyle }: Props) => {
    return (
        <article className={styles.cellContainer}>
            <h6>{header}</h6>
            <p className={bodyStyle ?? ''}>{body}</p>
        </article>
    );
};
