import { Contract } from 'api/contract/types';
import { format, isValid } from 'date-fns';
import { useFieldHistory } from 'features/contract/hooks/useFieldHistory';
import { HistoryKey } from 'features/contract/types/History';
import styles from './index.module.scss';

const ContractHistory = ({
    data,
    isOpen,
    historyKey,
    alignment,
}: {
    data: Contract;
    isOpen: boolean;
    historyKey: HistoryKey;
    alignment?: string;
}) => {
    const history = useFieldHistory(data, historyKey);

    return (
        <div className={`${styles.history} ${isOpen ? styles.open : ''}  ${alignment || ''} `}>
            <ul className={styles.history_list}>
                {history?.length ? (
                    history.map((h) => {
                        return (
                            <li
                                key={`${h.value}-${h.date}`}
                                className={`${styles.history_item} ${
                                    h.current ? styles.current : ''
                                }`}
                            >
                                <div className={styles.history_header}>
                                    <p className={styles.history_time}>
                                        {isValid(new Date(h.date))
                                            ? format(new Date(h.date), 'dd-MM-yyyy HH:mm:ss')
                                            : 'INVALID DATE'}
                                    </p>
                                    <p className={styles.history_value} data-testid="history-value">
                                        {h.value}
                                    </p>
                                </div>
                                <p className={styles.history_author}>{h.author}</p>
                            </li>
                        );
                    })
                ) : (
                    <li key="no-data" className={`${styles.history_item} ${styles.noData}`}>
                        N/A
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ContractHistory;
