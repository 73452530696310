import { QueryFunction } from 'react-query';

import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { isPandoraErrorResponse } from 'api/utils/isPandoraErrorResponse';
import axios from 'axios';
import { SEARCH_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';
import { SearchResponse, SearchType, searchPromocodeSchema } from './types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export type SearchQueryKey = [string, string, string];

export const searchItems: QueryFunction<SearchResponse, SearchQueryKey> = async ({ queryKey }) => {
    const token = await getAmplifyIdTokenFromCookie();

    const [, searchPath, searchQuery] = queryKey;

    const response = await axios(
        `${API_BASE_URL}/${SEARCH_BASE_PATH}/search/${searchPath}/?q=${searchQuery}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    const { data } = response;

    if (searchPath === SearchType.PROMOCODE) {
        const ajv = new Ajv({ coerceTypes: true });
        addFormats(ajv); // add formats for date-time format

        const validate = ajv.compile(searchPromocodeSchema);
        const valid = validate(data.data);

        if (!valid) {
            console.error(
                `Failed to validate promocodes search response. Error in ${JSON.stringify(
                    validate.errors?.[0].schemaPath
                )} - ${JSON.stringify(validate.errors?.[0].params)}`
            );
        }
    }

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from api: ${data.message}`);
    }

    return data;
};
