import { format, isToday, isYesterday } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { ActivityNote } from 'features/claim/api/claimActivity/type';
import Linkify from 'linkify-react';
import { ReactComponent as LineLogo } from './icons/dotted_vertical.svg';
import { ReactComponent as CommunicationLogo } from './icons/ellipse.svg';
import { ReactComponent as GreyLogo } from './icons/text_msg_grey.svg';

import style from './NoteBlock.module.scss';

const linkifyOptions = {
    target: '_blank',
    truncate: 42,
};

interface Props {
    note: ActivityNote;
    createdAt: Date;
}

export const formatAuthorNote = (author: string) => {
    const authorTrimmed = author.trim();
    const authorLowercase = authorTrimmed.toLowerCase();
    return authorLowercase[0].toUpperCase() + authorLowercase.slice(1);
};

export const NoteBlock = ({ note, createdAt }: Props) => {
    const formatDateString = (date: Date) => {
        if (isToday(date)) {
            return 'Today';
        }
        if (isYesterday(date)) {
            return 'Yesterday';
        }

        return format(date.getTime(), 'dd/MM/yyyy');
    };

    const formatTimeString = (date: Date) => {
        return formatInTimeZone(new Date(date), 'Europe/London', 'HH:mm');
    };

    const formatDateTimeString = (date: Date) => {
        return `${format(date.getTime(), 'yyyy-MM-dd')} ${formatTimeString(date)}`;
    };

    const formatAuthorInTitle = (createdBy: string) => {
        return (
            <>
                <span className={style.authorby}>by </span>
                {formatAuthorNote(createdBy)}
            </>
        );
    };

    return (
        <article className={style.noteBlockWrapper}>
            <figure className={style.iconWrapper}>
                {note.activityType === 'Communication' ? <CommunicationLogo /> : <GreyLogo />}
                <LineLogo />
            </figure>

            <article className={style.captionNotes}>
                <title>
                    {note.summary || 'Unknown'}{' '}
                    {note.createdBy && note.createdBy !== 'admin'
                        ? formatAuthorInTitle(note.createdBy)
                        : ''}
                </title>
                <Linkify tagName="p" className={style.description} options={linkifyOptions}>
                    {note.description?.includes('\n')
                        ? note.description.split('\n').map((line) => (
                              <span className={style.noteDescriptionLine} key={line}>
                                  {line}
                              </span>
                          ))
                        : note.description}
                </Linkify>
                <time dateTime={formatDateTimeString(createdAt)}>
                    <b>{formatDateString(createdAt)}</b> at <b>{formatTimeString(createdAt)}</b>
                </time>
            </article>
        </article>
    );
};
