import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { isPandoraErrorResponse } from 'api/utils/isPandoraErrorResponse';
import { getClaimResponseSchema } from './type';

export type GetJobByClaimQueryKey = [string, { id: string }];

export const validateClaimResponse = async (response: Response) => {
    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    const data = await response.json();

    const ajv = new Ajv();
    addFormats(ajv); // add formats for date-time format
    const validate = ajv.compile(getClaimResponseSchema);
    const valid = validate(data);

    if (!valid) {
        console.error(
            `Failed to validate getClaim response. Error in ${JSON.stringify(
                validate.errors?.[0].schemaPath
            )} - ${JSON.stringify(validate.errors?.[0].params)}`
        );
    }

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from api: ${data.message}`);
    }

    return data;
};
