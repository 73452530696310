import { Arrow, CheckFilled, Important } from 'assets/pandoraIcons';
import { toCurrency } from 'utils/toCurrency';
import { Cell } from './Cell';
import styles from './index.module.scss';

interface Props {
    headerLabel: string;
    totalCost?: number;
    limitValue?: number | null;
    setShowDetail?: (value: boolean) => void;
}

// totalCost in pence, limitValue in GBP
const CostCell = ({ headerLabel, totalCost = 0, limitValue, setShowDetail }: Props) => {
    const convertCostToPounds = Number((totalCost / 100).toFixed(2));

    const remainingLimit = limitValue ? limitValue - convertCostToPounds : 0;

    const isWarningVisible = remainingLimit !== null && remainingLimit <= 50 && limitValue !== null;
    const isOverLimit = remainingLimit !== null && remainingLimit < 0;

    return (
        <Cell
            header={
                <>
                    {setShowDetail ? (
                        <span
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => e.key === 'Enter' && setShowDetail(true)}
                            onClick={() => setShowDetail(true)}
                            className={`${styles.costSpan} ${styles.claim}`}
                        >
                            {headerLabel}
                            <Arrow />
                        </span>
                    ) : (
                        <span className={styles.costSpan}>{headerLabel}</span>
                    )}
                </>
            }
            body={
                <>
                    {limitValue ? (
                        <span className={styles.costLimit}>
                            {isWarningVisible ? (
                                <Important data-testid="warning-icon" />
                            ) : (
                                <CheckFilled data-testid="check-icon" />
                            )}
                        </span>
                    ) : null}
                    <span className={isOverLimit ? styles.errorMsg : ''}>
                        {totalCost && totalCost !== 0 ? toCurrency(totalCost) : '£0'}
                        {limitValue ? (
                            <span data-testid="claim-limit">
                                {` / £${limitValue}`} {isOverLimit && `(Max reached)`}
                            </span>
                        ) : null}
                    </span>
                </>
            }
            bodyStyle={styles.bodyStyle}
        />
    );
};

export default CostCell;
