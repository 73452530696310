import { Alert, AlertTitle } from '@mui/material';
import { LinkCell } from 'components/LinkCell';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import { DatabaseClaim } from 'features/claim/api/claim/type';
import { DatabaseJob } from 'features/claim/api/jobs/type';
import { useState } from 'react';
import { Cell } from './Cell';
import { CostBreakdown } from './CostBreakdown';
import CostCell from './CostCell';
import styles from './index.module.scss';

interface Props {
    isLoading: boolean;
    isError: boolean;
    data: DatabaseClaim | undefined;
    jobs: DatabaseJob[] | undefined;
    totalCost?: number | null;
    packageName?: string | null;
}

export const ClaimDetails = ({ data, isLoading, isError, totalCost, packageName, jobs }: Props) => {
    const [showOtherCost, setShowOtherCost] = useState(false);

    if (isLoading) {
        return (
            <section className={styles.skeletonContainer}>
                <SkeletonLoader repeat={2} />
            </section>
        );
    }

    const errorMsg = (
        <section className={styles.skeletonContainer}>
            <Alert severity="error">
                <AlertTitle>Claim Details Error</AlertTitle>
                Unable to load claim information for id
            </Alert>
        </section>
    );

    const noDataLoaded = isError || (!data && !isLoading);

    return (
        <section className={styles.claimDetailsContainer}>
            {data && !noDataLoaded ? (
                <>
                    <LinkCell
                        header="Claim Contract"
                        body={packageName || 'Loading'}
                        link={`/contract/${data.contract_id}`}
                        openInNewTab={false}
                    />

                    <Cell header="Claim Type" body={data.type || 'Unknown'} />
                    <Cell header="Claim Id" body={data.id.toString()} />
                    <Cell header="Claim Status" body={data.claim_status_name || 'Unknown'} />

                    <Cell
                        header="Start Date"
                        body={new Date(data.date_created).toLocaleDateString('en-UK')}
                    />
                    <Cell
                        header="Number of Completed Jobs"
                        body={
                            jobs
                                ? jobs
                                      .reduce(
                                          (accumulator, currentValue) =>
                                              currentValue.sk_job_status === 'Complete'
                                                  ? accumulator + 1
                                                  : accumulator,
                                          0
                                      )
                                      .toString()
                                : 'Unable to load job information'
                        }
                    />

                    {showOtherCost ? (
                        <CostBreakdown claim={data} setShowOtherCost={setShowOtherCost} />
                    ) : (
                        <CostCell
                            headerLabel="Total Claim Cost (ex. VAT)"
                            totalCost={data.charge_total_cost || 0}
                            limitValue={data.claim_cost_limit_gbp}
                            setShowDetail={setShowOtherCost}
                        />
                    )}
                    <CostCell
                        headerLabel="Total Contract Cost (ex. VAT)"
                        totalCost={totalCost || 0}
                        limitValue={data.annual_claim_cost_limit_gbp}
                    />

                    <Cell header="Urgency" body={data.sk_urgency || 'Normal'} />
                    <Cell header="Vulnerable Customer" body={data.sk_vulnerable_customer || 'No'} />
                </>
            ) : (
                errorMsg
            )}
        </section>
    );
};
