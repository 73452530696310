import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { isPandoraErrorResponse } from 'api/utils/isPandoraErrorResponse';
import { QueryFunction } from 'react-query';
import { CONTRACTS_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';
import { ContractResponse, getContractResponseSchema } from './types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');
export type Params = {
    log?: boolean;
    paymentStatus?: boolean;
    claimSource?: string;
    expand?: string;
    totalCost?: boolean;
};

export type GetContractByIdQueryKey = [string, number, Params];

export const getContractById: QueryFunction<
    ContractResponse,
    Partial<GetContractByIdQueryKey>
> = async ({ queryKey }) => {
    const [, contractId, params] = queryKey;
    let paramsString = '';

    if (params) {
        const { log, paymentStatus, claimSource, expand, totalCost } = params;
        const searchParams = new URLSearchParams('');
        searchParams.append('expand', `${expand}`);
        searchParams.append('log', `${log}`);
        searchParams.append('payment_status', `${paymentStatus}`);
        searchParams.append('claimSource', `${claimSource}`);
        searchParams.append('total_cost', `${totalCost}`);

        // Replace the encoded commas with commas
        paramsString += `?${searchParams.toString().replace(/%2C/g, ',')}`;
    }

    const token = await getAmplifyIdTokenFromCookie();

    const response = await fetch(
        `${API_BASE_URL}/${CONTRACTS_BASE_PATH}/contracts/${contractId || ''}${paramsString}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    const data = await response.json();
    let reformatData = data;

    if (reformatData.data[0].promocode && Array.isArray(reformatData.data[0].promocode)) {
        reformatData = { data: [{ ...data.data[0], promocode: {} }] };
    }

    const ajv = new Ajv({ coerceTypes: true, strict: false });
    addFormats(ajv); // add formats for date-time format
    const validate = ajv.compile(getContractResponseSchema);
    const valid = validate(reformatData);

    if (!valid) {
        console.error(
            `Failed to validate getContractById response. Error in ${JSON.stringify(
                validate.errors?.[0].schemaPath
            )} - ${JSON.stringify(validate.errors?.[0].params)}`
        );
    }

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from api: ${data.message}`);
    }

    return data;
};
